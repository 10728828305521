import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  inventoryItem: null,
  inventoryItems: [],
  success: false,
  error: null,
  message: null,
};

export const inventoryReducer = createReducer(initialState, (builder) => {
  builder
    // Create Inventory Item
    .addCase('createInventoryItemRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createInventoryItemSuccess', (state, action) => {
      state.isLoading = false;
      state.inventoryItem = action.payload;
      state.success = true;
      state.message = "Inventory item created successfully!";
    })
    .addCase('createInventoryItemFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Inventory Items
    .addCase('getAllInventoryItemsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllInventoryItemsSuccess', (state, action) => {
      state.isLoading = false;
      state.inventoryItems = action.payload;
    })
    .addCase('getAllInventoryItemsFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
      // Fetch Inventory by Store
    .addCase('getInventoryByStoreRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getInventoryByStoreSuccess', (state, action) => {
      state.isLoading = false;
      state.inventoryItems = action.payload;
    })
    .addCase('getInventoryByStoreFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Inventory Item
    .addCase('editInventoryItemRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editInventoryItemSuccess', (state, action) => {
      state.isLoading = false;
      state.inventoryItem = action.payload;
      state.success = true;
      state.message = "Inventory item updated successfully!";
    })
    .addCase('editInventoryItemFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Inventory Item
    .addCase('deleteInventoryItemRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteInventoryItemSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteInventoryItemFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
     // Transfer Stock
    .addCase('transferInventoryRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('transferInventorySuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.success = true;
    })
    .addCase('transferInventoryFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Add Stock
    .addCase('addInventoryRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('addInventorySuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.success = true;
    })
    .addCase('addInventoryFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
