/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllCategories } from '../../../redux/actions/categoryAction';
import { editProduct, getAllProducts } from '../../../redux/actions/productAction';
import { getAllProductUnits } from '../../../redux/actions/productUnitAction';
import { getAllProductVariants } from '../../../redux/actions/productVariantAction';
import { getAllSuppliers } from '../../../redux/actions/supplierAction';

const EditProductModal = ({ show, onHide, product }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.product);
  const { categories } = useSelector((state) => state.category);
  const { suppliers } = useSelector((state) => state.supplier);
  const { productUnits } = useSelector((state) => state.productUnit);
  const { productVariants } = useSelector((state) => state.productVariant);

  const [name, setName] = useState('');
  const [costPrice, setCostPrice] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [category, setCategory] = useState('');
  const [supplier, setSupplier] = useState('');
  const [unit, setUnit] = useState('');
  const [color, setColor] = useState('');
  const [availableIn, setAvailableIn] = useState('');
  const [dimension, setDimension] = useState('');
  const [taxable, setTaxable] = useState(false); // New state for taxable

  useEffect(() => {
    if (show) {
      dispatch(getAllCategories());
      dispatch(getAllSuppliers());
      dispatch(getAllProductUnits());
      dispatch(getAllProductVariants());
    }
  }, [show, dispatch]);

  useEffect(() => {
    if (product) {
      setName(product?.name || '');
      setCostPrice(product?.costPrice || '');
      setSellingPrice(product?.sellingPrice || '');
      setCategory(product?.category?._id || '');
      setSupplier(product?.supplier?._id || ''); // Use supplier._id
      setUnit(product?.unit?._id || ''); // Use unit._id
      setColor(product?.color || '');
      setAvailableIn(product?.availableIn || '');
      setDimension(product?.dimension || '');
      setTaxable(product?.taxable || false);
    }
  }, [product]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || !costPrice || !sellingPrice || !category || !supplier || !unit || !availableIn || !dimension) {
      toast.error('Please fill out all required fields.');
      return;
    }

    const updatedProductData = {
      name,
      costPrice,
      sellingPrice,
      category,
      supplier,
      unit,
      color,
      availableIn,
      dimension,
      taxable, // Include taxable field
    };
    // console.log(updatedProductData)
    dispatch(editProduct(product._id, updatedProductData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Product updated successfully!');
      dispatch(getAllProducts());
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const sizeVariant = productVariants ? productVariants.find(variant => variant.variantType === 'Size') : null;
  const colorVariant = productVariants ? productVariants.find(variant => variant.variantType === 'Color') : null;
  const weightVariant = productVariants ? productVariants.find(variant => variant.variantType === 'Weight') : null;

  const sizeOptions = sizeVariant ? sizeVariant.variations : [];
  const colorOptions = colorVariant ? colorVariant.variations : [];
  const weightOptions = weightVariant ? weightVariant.variations : [];

  return (
    <Modal show={show} onHide={onHide} backdrop="static" size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Edit Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                >
                  {categories && categories.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Cost Price</Form.Label>
                <Form.Control
                  type="number"
                  value={costPrice}
                  onChange={(e) => setCostPrice(e.target.value)}
                  min={0}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Selling Price</Form.Label>
                <Form.Control
                  type="number"
                  value={sellingPrice}
                  onChange={(e) => setSellingPrice(e.target.value)}
                  min={0}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Product Unit</Form.Label>
                <Form.Control
                  as="select"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  required
                >
                  {productUnits && productUnits.length > 0 ? (
                    productUnits.map((unit) => (
                      <option key={unit._id} value={unit._id}>
                        {unit.unitName}
                      </option>
                    ))
                  ) : (
                    <option disabled>No units available</option>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Supplier</Form.Label>
                <Form.Control
                  as="select"
                  value={supplier}
                  onChange={(e) => setSupplier(e.target.value)}
                  required
                >
                  {suppliers && suppliers.length > 0 ? (
                    suppliers.map((sup) => (
                      <option key={sup._id} value={sup._id}>
                        {sup.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No suppliers available</option>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Color</Form.Label>
                <Form.Control
                  as="select"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                >
                  <option value="">Select color</option>
                  {colorOptions && colorOptions.length > 0 ? (
                    colorOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))
                  ) : (
                    <option disabled>No colors available</option>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-2">
                <Form.Label>Available In</Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Size"
                    name="availableIn"
                    type="radio"
                    id="availableInSize"
                    checked={availableIn === 'Size'}
                    onChange={() => setAvailableIn('Size')}
                  />
                  <Form.Check
                    inline
                    label="Weight"
                    name="availableIn"
                    type="radio"
                    id="availableInWeight"
                    checked={availableIn === 'Weight'}
                    onChange={() => setAvailableIn('Weight')}
                  />
                </div>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-2">
                <Form.Label>{availableIn === 'Size' ? 'Size' : 'Weight'}</Form.Label>
                <Form.Control
                  as="select"
                  value={dimension}
                  onChange={(e) => setDimension(e.target.value)}
                >
                  <option value="">Select {availableIn === 'Size' ? 'size' : 'weight'}</option>
                  {(availableIn === 'Size' ? sizeOptions : weightOptions).map((variation, index) => (
                    <option key={index} value={variation}>
                      {variation}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Taxable"
                  checked={taxable}
                  onChange={(e) => setTaxable(e.target.checked)}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="text-center">
            <Button
              variant="primary"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'Update Product'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProductModal;
