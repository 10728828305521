import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';

import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal';
import CreateUserModal from './CreateUserModal.jsx';
import EditUserModal from './EditUserModal.jsx';
import { server } from '../../../server/server';

const UsersPage = () => {
  const dispatch = useDispatch();
  const { error, message, isLoading } = useSelector((state) => state.user);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [editingUser, setEditingUser] = useState(null);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axios.get(`${server}/user/get-all-users`, {
          withCredentials: true,
        });
        setUsers(data.users);
      } catch (error) {
        console.log("Error fetching users:", error.response?.data?.message || error.message);
        toast.error("Error fetching users.");
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: 'clearErrors' });
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch({ type: 'clearSuccess' });
    }
  }, [message, dispatch]);

  const handleDelete = (userId) => {
    setUserIdToDelete(userId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${server}/user/delete-user/${userIdToDelete}`, {
        withCredentials: true,
      });
      setUsers(users.filter((user) => user._id !== userIdToDelete));
      toast.success("User deleted successfully.");
    } catch (error) {
      console.log("Error deleting user:", error.response?.data?.message || error.message);
      toast.error("Error deleting user.");
    } finally {
      setShowConfirmDeleteModal(false);
      setUserIdToDelete(null);
    }
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setUserIdToDelete(null);
  };

  const handleEdit = (user) => {
    setEditingUser(user);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEditingUser(null);
  };

  return (
    <div className='container-fluid'>
      <Row>
        <Col className="d-flex align-items-center">
          <h2 className='mb-0'>Users</h2>
          <Button className='ms-auto' variant="primary" onClick={() => setShowCreateModal(true)}>
            Create User
          </Button>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <div className="table-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Role</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user._id}>
                    <td>{user.firstName} {user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.phoneNumber}</td>
                    <td>{user.role.name}</td>
                    <td>
                      {user.role.name !== "Administrator" && (
                        <>
                          <Button variant="info" onClick={() => handleEdit(user)}>
                            Edit
                          </Button>
                          <Button
                            variant="danger"
                            className='ms-2'
                            onClick={() => handleDelete(user._id)}
                            disabled={isLoading}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {showCreateModal && (
              <CreateUserModal
                show={showCreateModal}
                onHide={handleCreateModalClose}
              />
            )}
            {showModal && editingUser && (
              <EditUserModal
                show={showModal}
                onHide={handleModalClose}
                user={editingUser}
              />
            )}
            <ConfirmDeleteModal
              show={showConfirmDeleteModal}
              onClose={handleCloseConfirmDeleteModal}
              onConfirm={handleConfirmDelete}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UsersPage;
