import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteExpense, getAllExpenses } from '../../../redux/actions/expenseAction.js';
import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal';

import CreateExpenseModal from './CreateExpenseModal.jsx';
import EditExpenseModal from './EditExpenseModal.jsx';

const ExpensesPage = () => {
  const dispatch = useDispatch();
  const { expenses, error, message, isLoading } = useSelector((state) => state.expense);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [editingExpense, setEditingExpense] = useState(null);
  const [expenseIdToDelete, setExpenseIdToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllExpenses());
    if (error) {
      toast.error(error);
      dispatch({ type: 'clearErrors' });
    }

    if (message) {
      // toast.success(message); // Show success message if available
      dispatch({ type: 'clearSuccess' }); // Clear success message after showing
    }
  }, [error, message, dispatch]);

  const handleDelete = (expenseId) => {
    setExpenseIdToDelete(expenseId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteExpense(expenseIdToDelete));
    setShowConfirmDeleteModal(false);
    setExpenseIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setExpenseIdToDelete(null);
  };

  const handleEdit = (expense) => {
    setEditingExpense(expense);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEditingExpense(null);
  };

  return (
    <div className='container-fluid'>
      <Row className='mb-2'>
        <Col className="d-flex align-items-center">
          <h2 className="mb-0">Expenses</h2>
          <Button className='ms-auto' variant="primary" onClick={() => setShowCreateModal(true)}>
            Create Expense
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="table-container">
            <Table striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Category</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {expenses && expenses.map((expense, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{expense.description}</td>
                    <td>{expense.amount}</td>
                    <td>{new Date(expense.date).toLocaleDateString()}</td>
                    <td>{expense.category}</td>
                    <td>
                      <Button
                        variant="info"
                        onClick={() => handleEdit(expense)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        className='ms-2'
                        onClick={() => handleDelete(expense._id)}
                        disabled={isLoading}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {showCreateModal && (
              <CreateExpenseModal
                show={showCreateModal}
                onHide={handleCreateModalClose}
              />
            )}
            {showModal && editingExpense && (
              <EditExpenseModal
                show={showModal}
                onHide={handleModalClose}
                expense={editingExpense}
              />
            )}
            <ConfirmDeleteModal
              show={showConfirmDeleteModal}
              onClose={handleCloseConfirmDeleteModal}
              onConfirm={handleConfirmDelete}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExpensesPage;