import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import PaginationComponent from '../../components/pagination/PaginationComponent';

import { useDispatch, useSelector } from 'react-redux';
import { getSalesByStore } from '../../../redux/actions/saleAction.js';

import EditSaleModal from './EditSaleModal.jsx';
import ViewSaleModal from './ViewSaleModal.jsx';

const SalesPage = () => {
  const [selectedSale, setSelectedSale] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const { sales } = useSelector((state) => state.sale);
  const { user } = useSelector((state) => state.user);

  const storeId = user && user.storeId;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesByStore(storeId));
  }, [dispatch, storeId]);

  const handleView = (sale) => {
    setSelectedSale(sale);
    setShowViewModal(true);
  };

  const handleEdit = (sale) => {
    setSelectedSale(sale);
    setShowEditModal(true);
  };

  const handleCloseViewModal = () => {
    setShowViewModal(false);
    setSelectedSale(null);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedSale(null);
  };

  const productsPerPage = 13;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(sales.length / productsPerPage);

  // Calculate the products to display based on current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const currentProducts = sales.slice(startIndex, startIndex + productsPerPage);

  return (
    <div className="container-fluid">
      <h1 className="mt-1">Sales</h1>
      <Row>
        <Col>
          <div className='table-container'>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Customer</th>
                  <th>Total Amount</th>
                  <th>Payment Method</th>
                  <th>Cashier</th>
                  <th>Created At </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentProducts && currentProducts.map((sale, index) => (
                  <tr key={sale._id}>
                    <td>{index + 1 + startIndex}</td>
                    <td>{sale?.customer?.name}</td>
                    <td>{sale?.totalAmount}</td>
                    <td>{sale?.paymentMethod}</td>
                    <td>{sale?.cashier?.firstName}</td>
                    <td>{new Date(sale?.saleDate).toLocaleString('en-KE')}</td>

                    <td>
                      <Button
                        variant="info"
                        size="sm"
                        onClick={() => handleView(sale)}
                      >
                        View
                      </Button>{' '}
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleEdit(sale)}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Only show pagination if totalPages is greater than 1 */}
          {totalPages > 1 && (
            <div className="d-flex justify-content-center mt-2">
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            </div>
          )}
        </Col>
      </Row>
      {selectedSale && (
        <>
          <ViewSaleModal
            show={showViewModal}
            handleClose={handleCloseViewModal}
            sale={selectedSale}
          />
          <EditSaleModal
            show={showEditModal}
            handleClose={handleCloseEditModal}
            sale={selectedSale}
          />
        </>
      )}
    </div>
  );

};

export default SalesPage;