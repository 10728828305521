import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { getAllInventoryItems } from '../../../redux/actions/inventoryAction.js';
import ProductViewModal from './ProductViewModal.jsx';

const InventoryReport = () => {
    // const [products, setProducts] = useState([]); // Store fetched products
    const [groupedProducts, setGroupedProducts] = useState([]); // Store grouped products

    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const dispatch = useDispatch();

    // Get state from the Redux store
    const { inventoryItems } = useSelector((state) => state.inventory);

    // Memoized function to group products by name and size
    const groupProducts = useCallback(() => {
        const grouped = inventoryItems.reduce((acc, item) => {
            const productName = item.product.name; // Accessing the product name
            const size = item.size; // Accessing the size
            const costPrice = item.product.costPrice; // Accessing the cost price
            const sellingPrice = item.product.sellingPrice; // Accessing the selling price

            const existingProduct = acc.find(
                (prod) => prod.product === productName && prod.size === size
            );

            if (existingProduct) {
                existingProduct.totalQuantity += item.quantity; // Summing up the quantities
                existingProduct.variants.push({
                    color: item.color,
                    quantity: item.quantity,
                });
            } else {
                acc.push({
                    product: productName,
                    size: size,
                    costPrice: costPrice,
                    sellingPrice: sellingPrice,
                    totalQuantity: item.quantity,
                    variants: [{ color: item.color, quantity: item.quantity }],
                });
            }
            return acc;
        }, []);

        setGroupedProducts(grouped); // Update the state with the grouped products
    }, [inventoryItems]); // Dependencies include products

    // Effect to fetch products on component mount
    useEffect(() => {
        // Fetch inventory items from the Redux store
        dispatch(getAllInventoryItems());
        // fetchProducts();
    }, []);

    // Effect to group products whenever the products state changes
    useEffect(() => {
        if (inventoryItems.length > 0) {
            groupProducts(); // Call grouping function without parameters
        }
    }, [inventoryItems, groupProducts]); // Include groupProducts in dependencies

    // Handler for opening the modal and setting the product to view
    const handleView = (product) => {
        setSelectedProduct(product);
        setShowModal(true); // Open modal
    };

    // Handler for closing the modal
    const handleClose = () => {
        setShowModal(false);
        setSelectedProduct(null);
    };

    const columns = [
        {
            name: "#",
            selector: (_row, index) => index + 1,
            sortable: false,
            width: "60px",
        },
        {
            name: 'Product',
            selector: (row) => row.product,
            sortable: true,
        },
        {
            name: 'Size',
            selector: row => row.size, // Access the nested name property
        },
        {
            name: 'Quantity',
            selector: row => row.totalQuantity, // Access the nested name property
        },
        {
            name: 'Stock Value',
            selector: row => (row.totalQuantity * row.costPrice).toFixed(2) // Access the nested name property
        },
        {
            name: 'Sale Value',
            selector: row => (row.totalQuantity * row.sellingPrice).toFixed(2) // Access the nested name property
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <Button
                        variant="info"
                        size="sm"
                        onClick={() => handleView(row)}
                    >
                        View
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div className="container-fluid">
            <h2>Inventory Management</h2>
            <DataTable
                columns={columns}
                data={groupedProducts}
                pagination
                striped
                highlightOnHover
            />
            {/* Modal for showing product details */}
            <ProductViewModal show={showModal} onClose={handleClose} product={selectedProduct} />

        </div>
    );
};

export default InventoryReport;
