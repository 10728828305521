/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createStock, getAllStocks } from '../../../redux/actions/stockAction.js';
import { getAllProducts } from '../../../redux/actions/productAction';

const CreateStockModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.stock);
  const { products, productsLoading } = useSelector((state) => state.product);

  const [selectedProduct, setSelectedProduct] = useState('');
  const [quantity, setQuantity] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const stockData = {
      product: selectedProduct,
      quantity,
    };

    dispatch(createStock(stockData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Stock created successfully!');
      dispatch(getAllStocks()); // Fetch updated stock list
      setSelectedProduct('');
      setQuantity('');
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    dispatch(getAllProducts()); // Fetch products
  }, [dispatch]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Create Stock</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="product" className="form-label">Product</label>
            <select
              id="product"
              className="form-select"
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              disabled={productsLoading}
              required
            >
              <option value="">Select a product</option>
              {products.map((product) => (
                <option key={product._id} value={product._id}>
                  {product.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="quantity" className="form-label">Quantity</label>
            <input
              type="number"
              id="quantity"
              className="form-control"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              min={0}
              required
            />
          </div>

          <Button type="submit" variant="primary" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create Stock'}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateStockModal;