/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import { editStock } from '../../../redux/actions/stockAction';
import { getAllProducts } from '../../../redux/actions/productAction';

const EditStockModal = ({ show, onHide, stock }) => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product); // Adjust according to your Redux setup

  const [selectedProduct, setSelectedProduct] = useState('');
  const [quantity, setQuantity] = useState('');

  useEffect(() => {
    if (show) {
      dispatch(getAllProducts()); // Fetch products
    }
  }, [show, dispatch]);

  useEffect(() => {
    if (stock) {
      setSelectedProduct(stock?.product._id || ''); // Set initial value
      setQuantity(stock?.quantity || '');
    }
  }, [stock]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editStock(stock._id, { product: selectedProduct, quantity }));
    onHide(); // Close modal after submission
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Stock</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Product</Form.Label>
            <Form.Select
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              required
            >
              <option value="">Select a product</option>
              {products.map((product) => (
                <option key={product._id} value={product._id}>
                  {product.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditStockModal;