import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal';
import CreateStoreModal from './CreateStoreModal.jsx';
import EditStoreModal from './EditStoreModal.jsx';
import { deleteStore, getAllStores } from '../../../redux/actions/storeAction.js';
const StoresPage = () => {
  const dispatch = useDispatch();
  const { stores, isLoading } = useSelector((state) => state.store);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [editingStore, setEditingStore] = useState(null);
  const [storeIdToDelete, setStoreIdToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllStores());
  }, [dispatch]);

  const handleDelete = (storeId) => {
    setStoreIdToDelete(storeId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteStore(storeIdToDelete));
    setShowConfirmDeleteModal(false);
    setStoreIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setStoreIdToDelete(null);
  };

  const handleEdit = (store) => {
    setEditingStore(store);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setEditingStore(null);
  };

  return (
    <div className='container-fluid'>
      <Row>
        <Col className="d-flex align-items-center">
          <h2 className='mb-0'>Stores</h2>
          {/* <Button className='ms-auto' variant="primary" onClick={() => setShowCreateModal(true)}>
            Add Store
          </Button> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="table-container mt-2">
            <Table striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Location</th>
                  <th>Contact</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {stores && stores.map((store, index) => (
                  <tr key={store._id}>
                    <td>{index + 1}</td>
                    <td>{store.name}</td>
                    <td>{store.location}</td>
                    <td>{store.contact}</td>
                    <td>
                      <Button variant="info" onClick={() => handleEdit(store)}>
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        className='ms-2'
                        onClick={() => handleDelete(store._id)}
                        disabled={isLoading}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {showCreateModal && (
              <CreateStoreModal
                show={showCreateModal}
                onHide={handleCreateModalClose}
              />
            )}
            {showEditModal && editingStore && (
              <EditStoreModal
                show={showEditModal}
                onHide={handleEditModalClose}
                store={editingStore}
              />
            )}
            <ConfirmDeleteModal
              show={showConfirmDeleteModal}
              onClose={handleCloseConfirmDeleteModal}
              onConfirm={handleConfirmDelete}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StoresPage;