/* eslint-disable react/prop-types */
import React from 'react';

const FormattedAmount = ({ amount }) => {
    const formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);

    return <span>{formattedAmount}</span>;
};

export default FormattedAmount;
