import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import SaleViewModal from './SaleViewModal.jsx';
import { getAllSales } from '../../../redux/actions/saleAction.js';

const SalesReport = () => {
    const [groupedSales, setGroupedSales] = useState([]); // Store grouped sales data
    const [showModal, setShowModal] = useState(false);
    const [selectedSale, setSelectedSale] = useState(null);
    const dispatch = useDispatch();

    // Get state from the Redux store
    const { sales } = useSelector((state) => state.sale);

    // Memoized function to group sales data by product and date
    const groupSales = useCallback(() => {
        const grouped = sales.reduce((acc, item) => {
            const productName = item?.product?.name; // Accessing the product name
            const saleDate = new Date(item?.saleDate).toLocaleDateString(); // Formatting sale date
            const totalAmount = item?.totalAmount; // Accessing the total amount of the sale

            const existingSale = acc.find(
                (sale) => sale.product === productName && sale.saleDate === saleDate
            );

            if (existingSale) {
                existingSale.totalSales += totalAmount; // Summing up the total sales
                existingSale.totalQuantity += item.quantity; // Summing up quantities sold
            } else {
                acc.push({
                    product: productName,
                    saleDate: saleDate,
                    totalSales: totalAmount,
                    totalQuantity: item.quantity,
                });
            }
            return acc;
        }, []);

        setGroupedSales(grouped); // Update the state with the grouped sales data
    }, [sales]); // Dependencies include sales data

    // Effect to fetch sales data on component mount
    useEffect(() => {
        // Fetch sales data from the Redux store
        dispatch(getAllSales());
    }, [dispatch]);

    // Effect to group sales whenever the sales data state changes
    useEffect(() => {
        if (sales.length > 0) {
            groupSales(); // Call grouping function without parameters
        }
    }, [sales, groupSales]); // Include groupSales in dependencies

    // Handler for opening the modal and setting the sale to view
    const handleView = (sale) => {
        setSelectedSale(sale);
        setShowModal(true); // Open modal
    };

    // Handler for closing the modal
    const handleClose = () => {
        setShowModal(false);
        setSelectedSale(null);
    };

    const columns = [
        {
            name: "#",
            selector: (_row, index) => index + 1,
            sortable: false,
            width: "60px",
        },
        {
            name: 'Sale Date',
            selector: (row) => row?.saleDate,
        },
        // {
        //     name: 'Quantity Sold',
        //     selector: (row) => row?.totalQuantity,
        // },
        {
            name: 'Total Sales',
            selector: (row) => row?.totalSales.toFixed(2), // Display total sales
        },
        {
            name: 'Actions',
            cell: (row) => (
                <Button
                    variant="info"
                    size="sm"
                    onClick={() => handleView(row)}
                >
                    View
                </Button>
            ),
        },
    ];

    return (
        <div className="container-fluid">
            <h2>Sales Report</h2>
            <DataTable
                columns={columns}
                data={groupedSales}
                pagination
                striped
                highlightOnHover
            />
            {/* Modal for showing sale details */}
            <SaleViewModal show={showModal} onClose={handleClose} sale={selectedSale} />
        </div>
    );
};

export default SalesReport;
