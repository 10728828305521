

export const handleProductFilter = (data, searchTerm) => {
    return data.filter(row =>
        row?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
};

export const handleInventoryFilter = (data, searchTerm) => {
    return data.filter(row =>
        row?.product?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
};

// const handleFilter = (e) => {
//   const newData = data.filter(row =>
//     row.name.toLowerCase().includes(e.target.value.toLowerCase())
//   );
//   setRecords(newData);
// };
