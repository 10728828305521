import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStockByStore } from "../../../redux/actions/stockAction";

import ProductCard from "../productcard/ProductCard.jsx";
import PaginationComponent from "../pagination/PaginationComponent";

const ProductItems = () => {
  const [data, setData] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { stocks } = useSelector((state) => state.stock);

  const storeId = user && user?.store._id;
  const dispatch = useDispatch();

  useEffect(() => {
    if (storeId) {
      dispatch(getStockByStore(storeId));
    }
  }, [dispatch, storeId]);

  useEffect(() => {
    if (stocks && stocks.length > 0) {
      const extractedProducts = stocks.map((stock) => ({
        ...stock.product,
        quantity: stock.quantity,
      }));
      setData(extractedProducts);
    }
  }, [stocks]);

  const sortedData = useMemo(() => {
    if (data && data.length > 0) {
      return [...data].sort((a, b) => b.sold_out - a.sold_out);
    }
    return [];
  }, [data]);

  const productsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(sortedData.length / productsPerPage);
  const startIndex = (currentPage - 1) * productsPerPage;
  const currentProducts = sortedData.slice(startIndex, startIndex + productsPerPage);

  return (
    <div className="fixed-column">
      <div className="container-fluid">
        <div className="row gy-2">
          {currentProducts &&
            currentProducts.map((i, index) => <ProductCard data={i} key={index} />)}
        </div>
        <div className="row">
          <div className="col">
            {totalPages > 1 && (
              <div className="d-flex justify-content-center mt-2">
                <PaginationComponent
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItems;
