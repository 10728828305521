/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

const ProductViewModal = ({ show, onClose, product }) => {

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Product Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {product && (
                    <>
                        <p><strong>Product:</strong> {product.product}</p>
                        <p><strong>Size:</strong> {product.size}</p>
                        <p><strong>Total Quantity:</strong> {product.totalQuantity}</p>
                        <h5>Color Variants</h5>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Color</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {product.variants.map((variant, idx) => (
                                    <tr key={idx}>
                                        <td>{variant.color}</td>
                                        <td>{variant.quantity}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProductViewModal;
