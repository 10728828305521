import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationAlert = ({ show, onConfirm, onCancel }) => {
    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this product?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={onConfirm}>
                    Yes, delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationAlert;
