import React, { useEffect, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaArrowCircleRight, FaChartBar, FaChartLine, FaCreditCard, FaDollarSign, FaUsersCog } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllExpenses } from '../../../redux/actions/expenseAction.js';
import { getSalesByStore } from '../../../redux/actions/saleAction.js';
import { getStockByStore } from '../../../redux/actions/stockAction.js';
import SalesExpensesChart from './SalesExpensesChart.jsx';

// import TopSellingProductsChart from './TopSellingProductsChart.jsx';

import FormattedAmount from './FormattedAmount.jsx';

const AdminDashboard = () => {
  const { users, user } = useSelector((state) => state.user);
  const { sales } = useSelector((state) => state.sale);
  const { expenses } = useSelector((state) => state.expense);
  const { stocks } = useSelector((state) => state.stock);

  const dispatch = useDispatch();


  const storeId = user && user.store._id;


  useEffect(() => {
    if (user) {
      dispatch(getSalesByStore(storeId));
      dispatch(getAllExpenses());
      dispatch(getStockByStore(storeId));
    }
  }, [dispatch, storeId]);

  useEffect(() => {

  }, [sales, expenses]);



  const totalSales = useMemo(() => sales && sales.reduce((acc, sale) => acc + sale.totalAmount, 0), [sales]);
  const totalExpenses = useMemo(() => expenses && expenses.reduce((acc, expense) => acc + expense.amount, 0), [expenses]);
  const stockValue = useMemo(() => stocks && stocks.map(({ quantity, product }) => quantity * (product?.costPrice || 0)).reduce((acc, curr) => acc + curr, 0), [stocks]);

  const estimatedSales = useMemo(() => stocks && stocks.map(({ quantity, product }) => quantity * (product?.sellingPrice || 0)).reduce((acc, curr) => acc + curr, 0), [stocks]);

  const totalValue = useMemo(() => sales && sales.flatMap(sale => sale?.items?.map(item => item?.qty * item?.product?.costPrice)).reduce((acc, value) => acc + value, 0), [sales]);

  const grossProfit = totalSales - totalValue;
  const netProfit = grossProfit - totalExpenses;

  return (
    <div className="container-fluid mb-lg-0">
      <div className="row">
        <div className="col-sm-6">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>
      {/* Cards */}
      <Row className="gy-2">
        <Col lg={3} sm={6} xs={12}>
          <Card className="bg-success text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>{users && users.length}</h2>
                  <p>Users</p>
                </div>
                <FaUsersCog className="icon" />
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/users" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col lg={3} sm={6} xs={12}>
          <Card className="bg-success text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={totalSales} /></h2>
                  <p>Total Sales</p>
                </div>
                <FaDollarSign className="icon" />
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/sales" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col lg={3} sm={6} xs={12}>
          <Card className="bg-info text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={stockValue} /></h2>
                  <p>Total Stock Value</p>
                </div>
                <FaCreditCard className="icon" />
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/inventory" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12}>
          <Card className="bg-info text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={estimatedSales} /></h2>
                  <p>Estimated Sales</p>
                </div>
                <FaCreditCard className="icon" />
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/dashboard" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col lg={3} sm={6} xs={12}>
          <Card className="bg-success text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={grossProfit} /></h2>
                  <p>Gross Profit</p>
                </div>
                <FaDollarSign className="icon" />
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/sales" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col lg={3} sm={6} xs={12}>
          <Card className={`custom-panel ${netProfit < 0 ? "bg-danger" : "bg-primary"} text-white`}>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={netProfit} /></h2>
                  <p>{netProfit < 0 ? "Net Loss" : "Net Profit"}</p>
                </div>
                {netProfit < 0 ? (
                  <FaChartBar className="icon" />
                ) : (
                  <FaChartLine className="icon" />
                )}
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/financials" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        {/* New Cards for Daily, Weekly, and Monthly Sales */}
        {/* <Col lg={4} sm={6} xs={12}>
          <Card className="bg-primary text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.{dailyTotal.toFixed(2)}</h2>
                  <p>Daily Sales</p>
                </div>
                <FaChartLine className="icon" />
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/sales/daily" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col> */}

        {/* <Col lg={4} sm={6} xs={12}>
          <Card className="bg-secondary text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.{weeklyTotal.toFixed(2)}</h2>
                  <p>Weekly Sales</p>
                </div>
                <FaChartBar className="icon" />
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/sales/weekly" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col> */}
        {/* <Col lg={4} sm={6} xs={12}>
    <Card className="bg-dark text-white custom-panel">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2>Ksh.{monthlyTotal.toFixed(2)}</h2>
            <p>Monthly Sales</p>
          </div>
          <FaChartLine className="icon" />
        </div>
      </Card.Body>
      <Card.Footer>
        <Link to="/sales/monthly" className="text-white custom-text">
          More info <FaArrowCircleRight />
        </Link>
      </Card.Footer>
    </Card>
  </Col> */}
      </Row>


      {/* Add the Chart */}
      <Row className="gy-2 mt-4">
        <Col lg={6}>
          <Card className="bg-light">
            <Card.Body>
              <SalesExpensesChart
                sales={totalSales}
                expenses={totalExpenses}
              // dailySales={dailySales}
              // weeklySales={weeklySales}
              // monthlySales={monthlySales}
              />
            </Card.Body>
          </Card>
        </Col>
        {/* <Col lg={6}>
          <Card className="bg-light">
            <Card.Body>
              <TopSellingProductsChart products={exampleTopSellingProducts} />
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </div>
  );
};

export default AdminDashboard;
