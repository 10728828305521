/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const SaleViewModal = ({ show, onClose, sale }) => {
    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Sale Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {sale && (
                    <>
                        {/* <p><strong>Product:</strong> {sale.product}</p> */}
                        <p><strong>Sale Date:</strong> {sale.saleDate}</p>
                        {/* <p><strong>Quantity Sold:</strong> {sale.totalQuantity}</p> */}
                        <p><strong>Total Sales:</strong> Ksh.{sale.totalSales.toFixed(2)}</p>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SaleViewModal;
