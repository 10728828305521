// ActionButtons.jsx
import React from "react";
import { Button } from "react-bootstrap";

const ActionButtons = ({ onEdit, onDelete }) => {
    return (
        <div className="d-flex justify-content-between">
            <Button variant="warning" onClick={onEdit}>
                Edit
            </Button>
            <Button variant="danger" className="ms-2" onClick={onDelete}>
                Delete
            </Button>
        </div>
    );
};

export default ActionButtons;
