import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { handleInventoryFilter } from "../../components/utils/handleFilter";
import ActionButtons from "../../components/action-buttons/ActionButtons.jsx";

import { deleteInventoryItem, getAllInventoryItems } from "../../../redux/actions/inventoryAction"; // Adjust the import path as necessary


// import CreateInventory from "./CreateStock.jsx";
// import EditInventory from "./EditStockModal.jsx";
import { getAllStocks } from "../../../redux/actions/stockAction.js";
import CreateStockModal from "./CreateStock.jsx";
import EditStockModal from "./EditStockModal.jsx";

const InventoryPage = () => {
  // const { inventoryItems, isLoading, error } = useSelector((state) => state.inventory);
  const { stocks, isLoading, error } = useSelector((state) => state.stock);

  const dispatch = useDispatch();

  const [records, setRecords] = useState(stocks || []);
  const [searchTerm, setSearchTerm] = useState("");

  // const [selectedItem, setSelectedItem] = useState(null);


  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleShowCreateModal = () => setShowCreateModal(true);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingStock, setEditingStock] = useState(null);

  const handleCloseCreateModal = () => setShowCreateModal(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [stockIdToDelete, setStockIdToDelete] = useState(null);


  useEffect(() => {
    dispatch(getAllStocks());
  }, [dispatch]);


  useEffect(() => {
    setRecords(stocks || []);
  }, [stocks]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleInventoryFilter(stocks, term); // Use the utility function
    setRecords(filteredData);
  };

  const handleEdit = (item) => {
    setEditingStock(item);

    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditingStock(null);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      dispatch(deleteInventoryItem(id))
        .then(() => {
          toast.success("Inventory item deleted successfully");
          dispatch(getAllInventoryItems()); // Refresh the inventory list after deletion
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || "Error deleting item");
        });
    }
  };

  // useEffect(() => {
  //   // Show error toast if there's an error in fetching data
  //   if (error) {
  //     toast.error(error);
  //   }
  // }, [error]);

  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: 'Product',
      selector: (row) => row?.product?.name,
      sortable: true,
    },
    {
      name: 'Store',
      selector: row => row?.store?.name,
    },
    {
      name: 'Quantity',
      selector: row => row?.quantity,
    },
    // {
    //   name: "Cost Price",
    //   selector: (row) => `Ksh.${row?.product?.costPrice?.toFixed(2)}`,
    // },
    // {
    //   name: "Selling Price",
    //   selector: (row) => `Ksh.${row?.product?.sellingPrice?.toFixed(2)}`,
    // },
    {
      name: 'Created At',
      selector: row => new Date(row?.createdAt).toLocaleString('en-KE'),
    },
    {
      name: 'Updated At',
      selector: row => new Date(row?.updatedAt).toLocaleString('en-KE'),
    },
    {
      name: "Actions",
      cell: (row) => (
        <ActionButtons
          onEdit={() => handleEdit(row)}
          onDelete={() => handleDelete(row._id)}
        />
      ),
      width: "180px",
      ignoreRowClick: true,
    },
  ];


  // const handleConfirmDelete = () => {
  //   dispatch(deleteStock(stockIdToDelete));
  //   setShowConfirmDeleteModal(false);
  //   setStockIdToDelete(null);
  // };

  // const handleCloseConfirmDeleteModal = () => {
  //   setShowConfirmDeleteModal(false);
  //   setStockIdToDelete(null);
  // };

  // const handleCloseEditModal = () => {
  //   setShowEditModal(false);
  //   setEditingStock(null);
  // };

  return (
    <div className="container-fluid">
      <Row className="my-3 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Inventory</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search records..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
        <Col xs={6} md={4}>
          <Button className="float-end mb-2" variant="primary" onClick={handleShowCreateModal}>
            Add Inventory Item
          </Button>
        </Col>
      </Row>
      <DataTable
        columns={columns}
        data={records}
        pagination
        striped
        highlightOnHover
        progressPending={isLoading} // Show loader while fetching data
      />

      {/* Create Inventory Modal */}
      {/* <CreateInventory
        show={showCreateModal}
        handleClose={handleCloseCreateModal}
        fetchInventoryItems={() => dispatch(getAllInventoryItems())}
      /> */}

      {/* Edit Inventory Modal */}
      {/* <EditInventory
        show={showEditModal}
        handleClose={handleCloseEditModal}
        inventoryItem={selectedItem}
        fetchInventoryItems={() => dispatch(getAllInventoryItems())}
      /> */}

      {showCreateModal && (
        <CreateStockModal show={showCreateModal} onHide={() => setShowCreateModal(false)} />
      )}
      {showEditModal && editingStock && (
        <EditStockModal show={showEditModal} onHide={handleCloseEditModal} stock={editingStock} />
      )}
      {/* <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        onClose={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
      /> */}
    </div>
  );
};

export default InventoryPage;